// src/store/services/ShippingZones.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class ShippingZone extends BaseModel {}
ShippingZone.modelName = "ShippingZone";
ShippingZone.instanceDefaults = () => {
  return {
    hasExtra: false,
    countries: [],
    price: {
      ars: null,
      usd: 0
    },
    onlyProducts: []
  };
};
const servicePath = "shipping-zone";
const servicePlugin = makeServicePlugin({
  Model: ShippingZone,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
