// src/store/services/Dedicatorias.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Dedicatoria extends BaseModel { }
Dedicatoria.modelName = "Dedicatoria";
Dedicatoria.instanceDefaults = () => {
  return {
    hasExtra: false,
    countries: [],
    price: {
      ars: null,
      usd: 0
    },
    onlyProducts: []
  };
};
const servicePath = "dedicatoria";
const servicePlugin = makeServicePlugin({
  Model: Dedicatoria,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
