// src/store/services/DownloadCsvs.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class DownloadCsv extends BaseModel {}
DownloadCsv.modelName = "DownloadCsv";
DownloadCsv.instanceDefaults = () => {
  return {};
};
const servicePath = "download-csv";
const servicePlugin = makeServicePlugin({
  Model: DownloadCsv,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
