// src/store/services/DashboardStatss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class DashboardStats extends BaseModel {}
DashboardStats.modelName = "DashboardStats";
DashboardStats.instanceDefaults = () => {
  return {};
};
const servicePath = "dashboard-stats";
const servicePlugin = makeServicePlugin({
  Model: DashboardStats,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
