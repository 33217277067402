// src/store/services/Mailss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Mails extends BaseModel {}
Mails.modelName = "Mails";
Mails.instanceDefaults = () => {
  return {};
};
const servicePath = "mails";
const servicePlugin = makeServicePlugin({
  Model: Mails,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
