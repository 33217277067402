// src/store/services/Statess.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class States extends BaseModel {}
States.modelName = "States";
States.instanceDefaults = () => {
  return {};
};
// const servicePath = "country-state";
const servicePlugin = makeServicePlugin({
  Model: States,
  service: feathersClient.service("country-state"),
  servicePath: "states",
});

export default servicePlugin;
