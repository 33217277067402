// src/store/services/ScanQr.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class ScanQr extends BaseModel { }
ScanQr.modelName = "ScanQr";
ScanQr.instanceDefaults = () => {
  return {
    price: {
    },
  };
};
const servicePath = "scan-qr";
const servicePlugin = makeServicePlugin({
  Model: ScanQr,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
