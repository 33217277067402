// src/store/services/Tokenss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Tokens extends BaseModel {}
Tokens.modelName = "Tokens";
Tokens.instanceDefaults = () => {
  return {
    price: {
      ars: 0,
      usd: 0,
    },
  };
};
const servicePath = "tokens";
const servicePlugin = makeServicePlugin({
  Model: Tokens,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
