// src/store/services/products.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Product extends BaseModel { }
Product.modelName = "Product";
Product.instanceDefaults = () => {
  return {
    extra: {},
    price: {
      ars: 0,
      usd: 0,
    },
    customs: [],
    authors: [],
    customFields: [],
    size: {
      width: 0,
      height: 0,
      length: 0,
    },
    weight: 0,
    volumetric: 0,
    stockExtra: [],
  };
};
const servicePath = "products";
const servicePlugin = makeServicePlugin({
  Model: Product,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
