import Vue from "vue";
Vue.config.productionTip = false;
import { phone } from "arg.js";
import App from "./App.vue";
import "@/assets/styles.css";
import "@/assets/styles.scss";
import "@/imports.js";
import store from "./store";
import router from "./router";
import VCalendar from "v-calendar";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueCurrencyInput from "vue-currency-input";
import axios from "axios";
import VueAxios from "vue-axios";
import CountryFlag from "vue-country-flag";
import VTooltip from "v-tooltip";
import vueDebounce from 'vue-debounce'
import "vue-wysiwyg/dist/vueWysiwyg.css";
import wysiwyg from "vue-wysiwyg";
import VueFormulate from "@braid/vue-formulate";
import AudioRecorder from "vue-audio-recorder";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};


Vue.use(Toast, options);
Vue.use(AudioRecorder);

// VueFormulate en español
import { es } from "@braid/vue-formulate-i18n";
Vue.use(VueFormulate, {
  plugins: [es],
  locale: "es"
});


// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   Vue,
//   dsn: "https://805dc80fbfb245fc8c2b1df83bf5b978@o1077927.ingest.sentry.io/6081287",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "admin.hernancasciari.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

Vue.use(vueDebounce)
// import moment from 'moment-timezone'
// moment.tz.setDefault('America/Argentina/Buenos_Aires')

Vue.use(VTooltip);

Vue.component("country-flag", CountryFlag);
Vue.use(VueAxios, axios);
Vue.use(wysiwyg, {
  locale: {
    // Translations:
    justifyCenter: 'Centrar',
    justifyLeft: 'Justificar a la izquierda',
    justifyRight: 'Justificar a la derecha',
    bold: 'Negrita',
    code: 'Código',
    headings: 'Encabezados (h1-h6)',
    link: 'Hipervínculo',
    image: 'Insertar imagen',
    italic: 'Cursiva',
    orderedList: 'Lista ordenada',
    unorderedList: 'Lista desordenada',
    removeFormat: 'Eliminar formato.\nBorra encabezados, negritas, cursiva, subrayado, etc.',
    separator: null,
    table: 'Insertar tabla',
    underline: 'Subrayado'
  }
}); // config is optional. more below
const pluginOptions = {
  globalOptions: {
    currency: "ARS",
  },
};
Vue.use(VueCurrencyInput, pluginOptions);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VCalendar, {});
Vue.component("VueTimepicker", VueTimepicker);
// const pluginOptions = {
//   globalOptions: {
//     currency: "ARS"
//   }
// };

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("whatsapp", function (value) {
  if (!value) return "";
  try {

    return "https://api.whatsapp.com/send?phone=" + phone.clean(value).replace("+", "");
  } catch (err) {
    console.log("ERROR WHATSAPP", value);
    return null
  }
});

// Validation
import { CookieStorage } from "cookie-storage";
const cookieStorage = new CookieStorage();
import { extend } from "vee-validate";
import feathersClient from "./feathers-client.js";

import { required, email, digits } from "vee-validate/dist/rules";

// No message specified.
extend("digits", digits);
extend("email", email);
extend("required", {
  ...required,
  // message: "La contraseña es obligatoria.",
});

const isUnique = async (value, event) => {
  console.log("event", event);
  await feathersClient.authenticate({
    strategy: "jwt",
    accessToken: cookieStorage.getItem("products-online"),
  });
  let filterId = event
    ? {
      _id: {
        $ne: event[0],
      },
    }
    : {};
  const query = {
    shorter: value,
    ...filterId,
    $select: ["_id", "shorter"],
  };
  console.log(query);

  const products = await feathersClient.service("products").find({
    query: query,
  });

  if (products.length === 1) {
    const eventFound = products[0];
    const eventEditing = event[0];
    console.log(eventFound._id, eventEditing, eventFound._id === eventEditing);
    if (eventFound._id === eventEditing) {
      return true;
    }
  }

  if (products.length === 0) return true;
  return "El nombre {_value_} ya está en uso, prueba un nombre diferente.";
};

const orsaiMembersLength = async (value, event) => {
  console.log("event", event);

  return true
};

extend("unique", isUnique, ["event"]);
extend("orsai-members", orsaiMembersLength, ["event"]);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
