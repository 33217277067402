import { discard, iff } from "feathers-hooks-common";

import auth from "@feathersjs/authentication-client";
// src/feathers-client.js
import feathers from "@feathersjs/feathers";
import feathersVuex from "feathers-vuex";
import io from "socket.io-client";
import rest from "@feathersjs/rest-client";
import socketio from "@feathersjs/socketio-client";


const LOCAL_LIVE = false;

const apiURL =
  process.env.NODE_ENV === "production" || LOCAL_LIVE
    ? "https://api.orsai.org"
    : "http://localhost:3030";

// const apiURL = 'https://api.orsai.org'

const restClient = rest(apiURL).fetch(window.fetch);

const socket = io(apiURL, {
  transports: ["websocket"],
});

const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: 180000,
    })
  )
  .configure(
    auth({
      storage: window.localStorage,
    })
  )
  .hooks({
    before: {
      all: [
        iff(
          (context) => ["create", "update", "patch"].includes(context.method),
          discard("__id", "__isTemp")
        ),
      ],
    },
  });

feathersClient.use("/download-csv", restClient.service("download-csv"));

export default feathersClient;

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: "api", // optional for working with multiple APIs (this is the default value)
    idField: "_id", // Must match the id field in your database table/collection
    whitelist: ["$regex", "$search", "$options"],
    paramsForServer: ["$search"],
  }
);

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
