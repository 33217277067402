// src/store/services/Inscriptionss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Inscriptions extends BaseModel {}
Inscriptions.modelName = "Inscriptions";
Inscriptions.instanceDefaults = () => {
  return {};
};
const servicePath = "inscriptions";
const servicePlugin = makeServicePlugin({
  Model: Inscriptions,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
