import Vue from "vue";
import VTooltip from "v-tooltip";
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/es";
import { MazBtn, MazInput } from "maz-ui";
moment.locale("es");
Vue.prototype.$moment = moment;
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VTooltip);

Vue.use(MazBtn);
Vue.use(MazInput);
