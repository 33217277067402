// src/store/services/DescargarPagoEntradass.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class DescargarPagoEntradas extends BaseModel { }
DescargarPagoEntradas.modelName = "DescargarPagoEntradas";
DescargarPagoEntradas.instanceDefaults = () => {
    return {};
};
const servicePath = "descargar-pago-entradas";
const servicePlugin = makeServicePlugin({
    Model: DescargarPagoEntradas,
    service: feathersClient.service(servicePath),
    servicePath,
});

export default servicePlugin;
