// src/store/services/Orderss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Orders extends BaseModel {}
Orders.modelName = "Orders";
Orders.instanceDefaults = () => {
  return {};
};
const servicePath = "orders";
const servicePlugin = makeServicePlugin({
  Model: Orders,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
