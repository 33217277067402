// src/store/services/Updates.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Update extends BaseModel {}
Update.modelName = "Update";
Update.instanceDefaults = () => {
  return {};
};
const servicePath = "update";
const servicePlugin = makeServicePlugin({
  Model: Update,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
