// src/store/services/PendientesRetiros.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class PendientesRetiro extends BaseModel { }
PendientesRetiro.modelName = "PendientesRetiro";
PendientesRetiro.instanceDefaults = () => {
    return {};
};
const servicePath = "pendientes-retiro";
const servicePlugin = makeServicePlugin({
    Model: PendientesRetiro,
    service: feathersClient.service(servicePath),
    servicePath,
});

export default servicePlugin;
