// src/store/services/ProductSorts.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class ProductSort extends BaseModel { }
ProductSort.modelName = "ProductSort";
ProductSort.instanceDefaults = () => {
  return {};
};
const servicePath = "products-sort";
const servicePlugin = makeServicePlugin({
  Model: ProductSort,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
