// src/store/services/Categoriess.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Categories extends BaseModel {}
Categories.modelName = "Categories";
Categories.instanceDefaults = () => {
  return {};
};
const servicePath = "categories";
const servicePlugin = makeServicePlugin({
  Model: Categories,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
