// src/store/services/DescargarDhls.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class DescargarDhl extends BaseModel { }
DescargarDhl.modelName = "DescargarDhl";
DescargarDhl.instanceDefaults = () => {
  return {

  };
};
const servicePath = "descargar-dhl";
const servicePlugin = makeServicePlugin({
  Model: DescargarDhl,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
