// src/store/services/FoodOrderss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class FoodOrders extends BaseModel {}
FoodOrders.modelName = "FoodOrders";
FoodOrders.instanceDefaults = () => {
  return {};
};
const servicePath = "food-orders";
const servicePlugin = makeServicePlugin({
  Model: FoodOrders,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
