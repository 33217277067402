// src/store/services/Shipmentss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Shipments extends BaseModel {}
Shipments.modelName = "Shipments";
Shipments.instanceDefaults = () => {
  return {};
};
const servicePath = "shipments";
const servicePlugin = makeServicePlugin({
  Model: Shipments,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
