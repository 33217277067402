// src/store/services/Saless.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class Sales extends BaseModel {}
Sales.modelName = "Sales"
Sales.instanceDefaults = () => {
  return {}
}
const servicePath = 'sales'
const servicePlugin = makeServicePlugin({
  Model: Sales,
  service: feathersClient.service(servicePath),
  servicePath
})

export default servicePlugin
