// src/store/services/Variabless.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Variables extends BaseModel {}
Variables.modelName = "Variables";
Variables.instanceDefaults = () => {
  return {
    key: "indexHeader"
  };
};
const servicePath = "variables";
const servicePlugin = makeServicePlugin({
  Model: Variables,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
