// src/store/services/events.js

import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Tickets extends BaseModel {}
Tickets.modelName = "Tickets";
Tickets.instanceDefaults = () => {
  return {
    // Define aquí las propiedades específicas de un evento si las tienes
    // Por ejemplo:
    // fecha_del_evento: "",
    // date: "",
    // location: "",
    // ...
  };
};



const servicePath = "tickets"; // Asegúrate de que coincida con el nombre del servicio en el backend

const servicePlugin = makeServicePlugin({
  Model: Tickets,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
