// src/store/services/EstadisticasDhls.js
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class EstadisticasDhl extends BaseModel { }
EstadisticasDhl.modelName = "EstadisticasDhl";
EstadisticasDhl.instanceDefaults = () => {
  return {
  };
};
const servicePath = "estadisticas-dhl";
const servicePlugin = makeServicePlugin({
  Model: EstadisticasDhl,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
