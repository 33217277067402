// src/store/services/PagosAutoress.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class PagosAutores extends BaseModel {}
PagosAutores.modelName = "PagosAutores";
PagosAutores.instanceDefaults = () => {
  return {};
};
const servicePath = "pagos-autores";
const servicePlugin = makeServicePlugin({
  Model: PagosAutores,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
