// src/store/services/Branchss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Branchs extends BaseModel {}
Branchs.modelName = "Branchs";
Branchs.instanceDefaults = () => {
  return {
    price: {
      ars: 0,
      usd: 0,
    },
  };
};
const servicePath = "branchs";
const servicePlugin = makeServicePlugin({
  Model: Branchs,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
