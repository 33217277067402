// src/store/services/AndreaniBulks.js
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class AndreaniBulk extends BaseModel {}
AndreaniBulk.modelName = "AndreaniBulk";
AndreaniBulk.instanceDefaults = () => {
  return {
    amount: {
      ars: 0,
      usd: 0,
    },
    minTotal: {
      ars: 0,
      usd: 0,
    },
    type: "percentage"
  };
};
const servicePath = "andreani-bulk";
const servicePlugin = makeServicePlugin({
  Model: AndreaniBulk,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
