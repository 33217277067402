// src/store/services/Customerss.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class Customers extends BaseModel {}
Customers.modelName = "Customers"
Customers.instanceDefaults = () => {
  return {}
}
const servicePath = 'customers'
const servicePlugin = makeServicePlugin({
  Model: Customers,
  service: feathersClient.service(servicePath),
  servicePath
})

export default servicePlugin
