// src/store/services/Couponss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Coupons extends BaseModel {}
Coupons.modelName = "Coupons";
Coupons.instanceDefaults = () => {
  return {
    amount: {
      ars: 0,
      usd: 0,
    },
    minTotal: {
      ars: 0,
      usd: 0,
    },
    type: "percentage"
  };
};
const servicePath = "coupons";
const servicePlugin = makeServicePlugin({
  Model: Coupons,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
