// src/store/services/events.js

import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Eventos extends BaseModel { }
Eventos.modelName = "Eventos";
Eventos.instanceDefaults = () => {
  return {
    metodosPago: {
      stripe: true,
      paypal: true,
      mercadopago: true,
    },
    entradas: [],
    variables: {},
    precio: {
      eur: null,
      usd: null,
      ars: null,
    }
  };
};



const servicePath = "eventos"; // Asegúrate de que coincida con el nombre del servicio en el backend

const servicePlugin = makeServicePlugin({
  Model: Eventos,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
