<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    //  Si el dominio es distinto a admin.orsai.org, o localhost redirigir a admin.orsai.org
    if (
      window.location.hostname !== "admin.orsai.org" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.href = "https://admin.orsai.org";
    }
  },
  mounted() {
    // Agregan un listener para el scan-qr create
    this.$FeathersVuex.api.ScanQr.on("created", (data) => {
      const idUsr = data.idUsr;

      console.log("ScanQr created", data.idUsr, idUsr);

      // Si el usuario es el mismo que el logueado, se redirige a la página de retiros
      if (idUsr === this.$store.state.auth.user._id) {
        // Si esta en la pagina de retiros, no redirigir, recargar la pagina con el query param
        if (this.$route.name === "retiros") {
          return;
        }

        if (this.$route.name === "scan") {
          return;
        }

        if (this.$route.name === "login") {
          return;
        }

        this.$router.push({
          name: "retiros",
          query: { orderId: data.orderId },
        });
      }
    });
  },
};
</script>

<style lang="scss"></style>
