// src/store/services/PagoEventos.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class PagoEvento extends BaseModel { }
PagoEvento.modelName = "PagoEvento";
PagoEvento.instanceDefaults = () => {
    return {
    };
};
const servicePath = "pago-evento";
const servicePlugin = makeServicePlugin({
    Model: PagoEvento,
    service: feathersClient.service(servicePath),
    servicePath,
});

export default servicePlugin;
