// src/store/services/Totalss.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

class Totals extends BaseModel {}
Totals.modelName = "Totals"
Totals.instanceDefaults = () => {
  return {}
}
const servicePath = 'totals'
const servicePlugin = makeServicePlugin({
  Model: Totals,
  service: feathersClient.service(servicePath),
  servicePath
})

export default servicePlugin
