// src/store/services/CountryStates.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class CountryState extends BaseModel {}
CountryState.modelName = "CountryState";
CountryState.instanceDefaults = () => {
  return {};
};
const servicePath = "country-state";
const servicePlugin = makeServicePlugin({
  Model: CountryState,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
