// src/store/services/GiftCards.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class GiftCard extends BaseModel { }
GiftCard.modelName = "GiftCard";
GiftCard.instanceDefaults = () => {
  return {
    beneficiario: {
      nombre: "",
      email: "",
    },
    codigo: "",
  };
};
const servicePath = "gift-card";
const servicePlugin = makeServicePlugin({
  Model: GiftCard,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
