// src/store/services/BonosCaneloness.js
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class BonosCanelones extends BaseModel {}
BonosCanelones.modelName = "BonosCanelones";
BonosCanelones.instanceDefaults = () => {
  return {
    key: "indexHeader"
  };
};
const servicePath = "bonos-canelones";
const servicePlugin = makeServicePlugin({
  Model: BonosCanelones,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
