// src/store/services/Shippingss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Shippings extends BaseModel {}
Shippings.modelName = "Shippings";
Shippings.instanceDefaults = () => {
  return {
    archive: false
  };
};
const servicePath = "shippings";
const servicePlugin = makeServicePlugin({
  Model: Shippings,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
