// src/store/services/RegaloAudios.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class RegaloAudio extends BaseModel { }
RegaloAudio.modelName = "RegaloAudio";
RegaloAudio.instanceDefaults = () => {
  return {
  };
};
const servicePath = "regalo-audio";
const servicePlugin = makeServicePlugin({
  Model: RegaloAudio,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
