// src/store/services/EstadisticasAutoress.js
import feathersClient, { BaseModel, makeServicePlugin } from "../../feathers-client";

class EstadisticasAutores extends BaseModel { }
EstadisticasAutores.modelName = "EstadisticasAutores";
EstadisticasAutores.instanceDefaults = () => {
  return {
  };
};
const servicePath = "estadisticas-autores";
const servicePlugin = makeServicePlugin({
  Model: EstadisticasAutores,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
