// src/store/services/userss.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Users extends BaseModel {}
Users.modelName = "Users";
Users.instanceDefaults = () => {
  return {
    email: "",
    password: "",
  };
};
const servicePath = "users";
const servicePlugin = makeServicePlugin({
  Model: Users,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
