// src/store/services/DescargarParticipantess.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class DescargarParticipantes extends BaseModel { }
DescargarParticipantes.modelName = "DescargarParticipantes";
DescargarParticipantes.instanceDefaults = () => {
    return {};
};
const servicePath = "descargar-participantes";
const servicePlugin = makeServicePlugin({
    Model: DescargarParticipantes,
    service: feathersClient.service(servicePath),
    servicePath,
});

export default servicePlugin;
