// src/store/services/Mercadopagos.js
import feathersClient, { makeServicePlugin, BaseModel } from "../../feathers-client";

class Mercadopago extends BaseModel { }
Mercadopago.modelName = "Mercadopago";
Mercadopago.instanceDefaults = () => {
  return {};
};
const servicePath = "mercadopago";
const servicePlugin = makeServicePlugin({
  Model: Mercadopago,
  service: feathersClient.service(servicePath),
  servicePath,
});

export default servicePlugin;
